<template>
  <div class="container">
    <!-- 添加按钮 -->
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          style="margin-bottom: 10px"
          @click="LotNumberFormCreate"
      >添加
      </el-button
      >
    </div>
    <!-- 表格显示 -->
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%">
      <el-table-column label="批号" align="center">
        <template v-slot="{ row }">
          <span>{{ row.StrLotNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="390px"
      >
        <template v-slot="{ row }">
          <!-- <el-button type="primary" size="mini" @click="handleUpdate(row)">编辑</el-button> -->
          <el-button type="primary" size="mini" @click="BookFormCreate(row)">新增
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)"
          >删除
          </el-button
          >
          <el-button type="info" size="mini" @click="LotNumberFormUpdate(row)"
          >修改
          </el-button
          >
          <el-button size="mini" type="success" @click="gotoBookfile(row)"
          >修改当前批次录入内容
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="margin-top: 50px; text-align: right">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"/>
    </div>
    <!-- 添加按钮点击弹出框 -->
    <el-dialog
        :title="textMap[dialogStatus]"
        v-model="dialogFormVisible"
        top="7vh"
        :close-on-click-modal="false"
    >
      <el-form
          ref="dataForm"
          :model="formModel"
          size="small"
          :rules="rules"
          label-width="100px"
      >
        <el-row>
          <el-col>
            <el-form-item label="批号" prop="StrLotNumber">
              <el-input v-model="formModel.StrLotNumber"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right; border: 10px">
        <el-button size="mini" @click="dialogFormVisible = false"
        >取消
        </el-button
        >
        <el-button type="primary" size="mini" @click="LotNumberAddorUpdate"
        >确定
        </el-button
        >
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
    <!-- 根据批号新增 -->
    <el-dialog
        :title="`正在录入${StrLotNumber}批次图书`"
        width="60%"
        v-model="dialogLRTSVisible"
        top="7vh"
        :close-on-click-modal="false"
    >
      <!-- 第一个表单 -->
      <el-form label-width="100px" size="small" :model="formCXTSModel">
        <el-row :gutter="20">
          <el-col>
            <el-form :inline="true" label-width="100px">
              <!-- @submit.native.prevent -->
              <el-col :span="8">
                <el-form-item label="检索字段" prop="title">
                  <el-radio
                      v-model="formCXTSModel.keyName"
                      label="StrISBN"
                      @change="radioChange">ISBN
                  </el-radio>
                  <el-radio
                      v-model="formCXTSModel.keyName"
                      label="StrPositiveTitle"
                      @change="radioChange">正题名
                  </el-radio>
                </el-form-item>
              </el-col>
              <el-form-item label="检索内容" prop="title">
                <el-input v-model.trim="formCXTSModel.keywords" size="small" ref="checkContent" @input="handleInput" @keydown.enter.prevent/>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" @click="BookSearch(1)"
                >查询
                </el-button
                >
              </el-form-item>
              <el-form-item v-show="radioName === 'StrISBN'">
                <el-button
                    v-show="!isTSCXQuery"
                    size="mini"
                    type="primary"
                    @click="BookSearch(2)"
                >联机
                </el-button
                >
                <el-button
                    v-show="isTSCXQuery"
                    size="mini"
                    type="primary"
                    :loading="true"
                >联机查询中
                </el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-form>
      <!-- 第二个表单 -->
      <el-form
          ref="tsDataForm"
          label-width="100px"
          size="small"
          :model="formLRTSModel"
          :rules="tsRules"
      >
        <el-row>
          <el-col :span="8" style="border: #cccccc">
            <el-form-item label="封面:">
              <!-- <img
                :src="formLRTSModel.StrCover"
                v-if="formLRTSModel.StrCover"
                style="width: 100%;height: 200px"
              />-->
              <!--              <el-upload-->
              <!--                class="avatar-uploader"-->
              <!--                action="#"-->
              <!--                :http-request="overwriteAction"-->
              <!--                :show-file-list="false"-->
              <!--              >-->
              <!--                <img-->
              <!--                  v-if="formLRTSModel.StrCover"-->
              <!--                  :src="formLRTSModel.StrCover"-->
              <!--                  class="avatar"-->
              <!--                />-->
              <!--                <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
              <!--              </el-upload>-->
              <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  accept=".jpg,.jpeg,.png"
                  :headers="picheaders"
                  :data="uploadtype"
                  :class="{disabled:uploadDisabled}"
                  list-type="picture-card"
                  :before-upload="beforeUpload"
                  :on-preview="handlePictureCardPreview"
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                  :on-success="handleSuccess"
                  :file-list="fileList">
                <i class="el-icon-plus"/>
                <!--              accept=".jpg,.jpeg,.png"-->
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="正题名" prop="StrPositiveTitle">
              <el-input
                  v-model="formLRTSModel.StrPositiveTitle"
                  placeholder="请填写正题名"
              />
            </el-form-item>
            <el-form-item label="第一责任者" prop="StrFirstResponsible">
              <el-input
                  v-model="formLRTSModel.StrFirstResponsible"
                  placeholder="请填写第一责任者"
              />
            </el-form-item>
            <el-form-item label="其他责任者">
              <el-input
                  v-model="formLRTSModel.StrOtherResponsible"
                  class="input-with-select"
                  placeholder="请填写其他责任者"
              />
            </el-form-item>
            <el-form-item label="并列题名">
              <el-input
                  v-model="formLRTSModel.StrParallelTitle"
                  placeholder="请填写并列题名"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="装订" prop="Title">
              <el-input
                  v-model="formLRTSModel.StrBinding"
                  placeholder="请填写装订"
              />
            </el-form-item>
            <el-form-item label="卷页数">
              <el-input
                  v-model="formLRTSModel.StrNumberPages"
                  placeholder="请填写卷页数"
              />
            </el-form-item>
            <el-form-item label="副题名">
              <el-input
                  v-model="formLRTSModel.StrSubtitle"
                  placeholder="请填写副题名"
              />
            </el-form-item>

            <el-form-item label="主题词">
              <el-input
                  v-model="formLRTSModel.StrKeywords"
                  placeholder="请填写主题词"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版本">
              <el-input
                  v-model="formLRTSModel.StrVersion"
                  placeholder="请填写版本"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卷册名">
              <el-input
                  v-model="formLRTSModel.StrSectionNumber"
                  placeholder="请填写卷册名"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="丛书题名">
              <el-input
                  v-model="formLRTSModel.StrSeriesTitle"
                  placeholder="请填写丛书题名"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ISBN" prop="StrISBN">
              <el-input
                  v-model="formLRTSModel.StrISBN"
                  placeholder="请填写ISBN"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出版日期">
              <el-input
                  v-model="formLRTSModel.StrPublicationDate"
                  placeholder="请填写出版日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出版地">
              <el-input
                  v-model="formLRTSModel.StrPublisherPlace"
                  placeholder="请填写出版地"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出版社">
              <el-input
                  v-model="formLRTSModel.StrPublisher"
                  placeholder="请填写出版社"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="附注">
              <el-input
                  v-model="formLRTSModel.StrRemark"
                  placeholder="请填写附注"
              />
            </el-form-item>
          </el-col>

<!--辅助区分号-->
          <el-col :span="8">
            <el-row>
              <el-col :span="12">
                <el-form-item label="分类号" prop="StrTypeName">
                  <el-input
                      v-model="formLRTSModel.StrTypeName"
                      placeholder="请填写分类号"
                      @blur="check"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="辅助区分号" prop="StrAuxiliaryTypeName">
                  <el-input
                      v-model="formLRTSModel.StrAuxiliaryNum"
                      placeholder="请填写辅助区分号"
                      @blur="check"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>



          <el-col :span="8">
            <el-form-item label="每套册数">
              <el-input
                  v-model="formLRTSModel.IntReservationRoyalty"
                  placeholder="请填写每套册数"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="每册价格" prop="FloatPricePer">
              <el-input
                  v-model="formLRTSModel.FloatPricePer"
                  placeholder="请填写每册价格"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卷册">
              <el-input
                  v-model="formLRTSModel.StrSectionName"
                  placeholder="请填写卷册"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复本数">
              <el-input
                  v-model="formLRTSModel.IntNumberCopies"
                  placeholder="请填写复本数"/>
            </el-form-item>
            <el-form-item label="书屋位置" prop="StrLibraryID">
              <el-select
                  v-model="formLRTSModel.StrLibraryID"
                  placeholder="请选择书屋位置"
                  :disabled="isSelect"
                  @change="bookLibraryChange">
                <el-option
                    v-for="item in swList"
                    :key="item.StrName"
                    :label="item.StrName"
                    :value="item.StrID"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="条形码" prop="StrBookBarcode">
              <el-input
                  v-model="formLRTSModel.StrBookBarcode"
                  @input="getInput"
                  class="barcodeInput"
                  style="width: 60%"
                  ref="bookBarcode"
                  placeholder="请填写条形码"
              />
              <div style="width: 40%;display: inline-block">
                <el-switch
                    style="float:right;"
                    v-model="isBarCodeAutoincrement"
                    active-text="自增">
                </el-switch>
              </div>
            </el-form-item>
            <el-form-item label="书架">
              <el-select
                  v-model="formLRTSModel.StrBookshelfID"
                  placeholder="请选择书架"
                  filterable
              >
                <el-option
                    v-for="item in sjList"
                    :key="item.StrID"
                    :label="item.StrName"
                    :value="item.StrID"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="简介">
              <el-input
                  type="textarea"
                  rows="4"
                  v-model="formLRTSModel.StrSummary"
                  placeholder="请填写简介"
              />
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <!-- <el-form-item>
              <div
                v-show="IntBookBarcodeLength"
                style="color:red"
              >{{ `图书馆默认条码是${IntBookBarcodeLength}位，长度不足会自动转换为${IntBookBarcodeLength}位` }}</div>
              <div v-show="!IntBookBarcodeLength" style="color:red">请在系统设置中添加条码长度</div>
              <div style="color:red">如果联机查询内容或者封面有误，请点击报错按钮上报给管理员</div>
            </el-form-item> -->
          </el-col>
          <span class="dialog-footer" style="float: right">
            <el-button size="mini" type="primary" @click="BookRecorrect">报错</el-button>
            <el-button
                v-show="!isTSXG"
                size="mini"
                type="primary"
                @click="BookCreate"
            >添加</el-button
            >
            <el-button size="mini" type="primary" @click="btnEmpty"
            >清空</el-button
            >
          </span>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
        title="图书查询结果"
        v-model="dialogCXJGVisible"
        top="7vh"
        :close-on-click-modal="false">
      <el-table
          v-loading="cxjgLoading"
          :data="cxjgList"
          border
          fit
          highlight-current-row
          style="width: 100%">
        <el-table-column label="正题名" align="center" width="200px">
          <template v-slot="{ row }">
            <span>{{ row.StrPositiveTitle }}</span>
          </template>
        </el-table-column>

        <el-table-column label="分类号/种次号" align="center" width="170px">
          <template v-slot="{ row }">
            <span>{{ row.StrCallNumber }}</span>
          </template>
        </el-table-column>

        <el-table-column label="ISBN" align="center" width="170px">
          <template v-slot="{ row }">
            <span>{{ row.StrISBN }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button type="primary" size="mini" @click="BookUpdate(row)"
            >选择
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right">
        <el-pagination
            background
            :total="tsTotal"
            :current-page="cxjgQuery.Page"
            :page-size="cxjgQuery.Perpage"
            :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
            layout="prev,pager,next,jumper,sizes,total"
            @size-change="cxjgPageSizeChange"
            @current-change="cxjgCurrPageChange"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Compressor from "compressorjs";
import md5 from "js-md5";
import global from "@/utils/global";

export default {
  name: "bookcatalog",

  data() {
    return {
      showMsg: '',
      timerId: null,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
      },
      cxjgQuery: {
        Page: 1,
        Pager: 10,
      },
      formCXTSModel: {
        pageIndex: 1,
        pageSize: 10,
        keyName: "StrISBN",
        keywords: "",
      },
      uploadtype: {
        type: 'image/bookcover'
      },
      dialogFormVisible: false,
      dialogLRTSVisible: false,
      textMap: {
        update: "编辑",
        create: "添加",
      },
      dialogStatus: "",
      rules: {
        LotNumbers: [
          {required: true, message: "请输入批号", trigger: "blur"},
        ],
      },
      tsRules: {
        StrPositiveTitle: [
          {required: true, message: "请输入正题名", trigger: "blur"},
        ],
        StrFirstResponsible: [
          {required: true, message: "请输入第一责任者", trigger: "blur"},
        ],
        StrTypeName: [
          {required: true, message: "请输入分类号", trigger: "blur"},
        ],
        StrBookBarcode: [
          {required: true, message: "请输入条形码", trigger: "blur"},
        ],
        FloatPricePer: [
          {required: true, message: "请输入每册价格", trigger: "blur"},
        ],
        StrBookNumber: [
          {required: true, message: "请输入种次号", trigger: "blur"},
        ],
        StrISBN: [{required: true, message: "请输入ISBN", trigger: "blur"}],
        StrLibraryID: [
          {required: true, message: "请选择书屋", trigger: "change"},
        ],
      },
      formModel: {},
      formLRTSModel: {
        intReservationRoyalty: "",
        intMemoryCopyNum: "",
        strCollectionLocationId: "",
        strCollectionLocation: "",
        StrAuxiliaryNum:""
      },
      LotNumbers: "",
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      swList: [],
      sjList: [],
      dialogCXJGVisible: false,
      cxjgLoading: true,
      cxjgList: [],
      uploadUrl: "",
      tsTotal: 0,
      type: {
        strModule: "book",
        // token: getToken()
      },
      picheaders: {},
      fileList: [],
      isTSXG: false,
      IntBookBarcodeLength: "",
      isTSCXQuery: false,
      isSelect: null,
      radioName: "StrISBN",
      //是否开启条形码自增功能
      isBarCodeAutoincrement: false,
      //当前添加完后的最后一个条形码
      finallyBarCoder: "",
      StrLotNumber: '',
      StrLotNumberID: ''
    };
  },
  computed: {
    uploadDisabled: function () {
      if (this.fileList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getLotNumberList();
    this.uploadUrl = global.syOssUrl + '/upload2Oss'
    // this.getSWList()
    // this.fetchSystemView()
    // this.getSZDetail()
    // this.uploadUrl = process.env.VUE_APP_BASE_API + '/uploadFile/addFile'
  },
  methods: {
    // 自定义图片上传方法
    overwriteAction(data) {
      let _this = this;
      let rd = new FileReader(); // 创建文件读取对象
      let file = data.file;
      rd.readAsDataURL(file); // 文件读取装换为base64类型
      rd.onloadend = function (e) {
        console.log(e);
        _this.formLRTSModel.StrCover = this.result; // this指向当前方法onloadend的作用域
      };
    },
    // 判断分类号是否异常
    check() {
      if (this.checkSpecificKey(this.formLRTSModel.StrTypeName)) {
        this.$message({
          message: "分类号不能包含.以外的其他符号,请检查分类号",
          type: "error",
        });
      }
    },
    checkSpecificKey(str) {
      var specialKey =
          "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return true;
        }
      }
      return false;
    },
    // 获取批号
    getLotNumberList() {
      this.$axios({
        url: this.localpath + "lotnumbers",
        method: "post",
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.list = res.data.msg;
          this.total = res.data.total;
          this.listLoading = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.listLoading = false;
        }
      });
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'jpg'
      const extension2 = testmsg.toLowerCase() === 'jepg'
      const extension3 = testmsg.toLowerCase() === 'png'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传图片只能是jpg/jepg/png格式!',
          type: 'warning'
        });
        return false
      }
      return new Promise((resolve, reject) => {
        this.now = Date.parse(new Date()) / 1000;
        this.picheaders = {
          'timestamp': this.now,
          'sign': md5('6486b66b41f8df68777eac23' + this.now)
        },
            new Compressor(file, {
              quality: 0.6, // 设置压缩质量，范围为 0 到 1，默认为 0.8
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
      });
    },
    handlePictureCardPreview(file) {
      //默认是下载而不是打开
      window.open(file.url)
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.formLRTSModel.StrCoverURL = ''
      setTimeout(() => {
        this.fileList = fileList
      }, 1000); // 1 秒延迟
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 0) {
        this.fileList = fileList
        this.formLRTSModel.StrCoverURL = response.msg
      }
    },
    getInput() {
      clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        if (
            this.formLRTSModel.StrBookBarcode.length !==
            parseInt(this.IntBookBarcodeLength)
        ) {
          return this.$message.error(
              "条形码位数必须" + this.IntBookBarcodeLength + "位"
          );
        }
      }, 500)

    },
    //选择ISBN后，输入ISBN后自动联机查询
    handleInput() {
      if (this.radioName === 'StrISBN'&&this.formCXTSModel.keywords.length === 13) {
        this.BookSearch(2)
      }
    },
    // 获取书屋
    getLibraryList() {
      this.$axios({
        url: this.localpath + "librarys",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.swList = res.data.msg;
          this.DefaultLibrary();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 获取书架
    LibraryChange(val) {
      const param = {
        StrLibraryID: val,
      };
      //获取书架
      this.$axios({
        url: this.localpath + "bookshelfs",
        method: "post",
        data: JSON.stringify(param),
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          //先清空
          this.formLRTSModel.StrBookshelfID = ''
          this.sjList = res.data.msg;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
      // this.getBookcode(val);
    },
    // 书屋变更
    bookLibraryChange(){
      this.LibraryChange(this.formLRTSModel.StrLibraryID)
      this.getBookcode(this.formLRTSModel.StrLibraryID);
    },
    //通过图书馆配置获取规定图书条码长度
    getBookcode(val) {
      this.$axios({
        url: this.localpath + "librarys/" + val,
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.IntBookBarcodeLength = res.data.msg.IntBookBarcodeLength;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 是否隐藏联机按钮
    radioChange(val) {
      this.radioName = val;
      this.isTSCXQuery = false;
    },
    // 批号确认添加或修改
    LotNumberAddorUpdate() {
      //判断是添加还是修改
      if (this.dialogStatus === "create") {
        //添加
        this.$refs["dataForm"].validate((valid) => {
          if (valid) {
            this.$axios({
              url: this.localpath + "lotnumbers",
              method: "put",
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers: {
                "Content-Type": "application/json",
                dataType: "json",
                Authorization: localStorage.getItem("token"),
              },
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.getLotNumberList();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
                this.getLotNumberList();
              }
            });
            this.dialogFormVisible = false;
          }
        });
      } else {
        //修改
        this.$refs["dataForm"].validate((valid) => {
          if (valid) {
            this.$axios({
              url: this.localpath + "lotnumbers/" + this.formModel.StrID,
              method: "patch",
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers: {
                "Content-Type": "application/json",
                dataType: "json",
                Authorization: localStorage.getItem("token"),
              },
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.getLotNumberList();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
                this.getLotNumberList();
              }
            });
            this.dialogFormVisible = false;
          }
        });
      }
    },
    // 弹出批号添加对话框
    LotNumberFormCreate() {
      this.formModel = {};
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    //弹出批号修改对话框
    LotNumberFormUpdate(row) {
      this.formModel = {};
      this.formModel.StrID = row.StrID;
      this.formModel.StrLotNumber = row.StrLotNumber;
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    //修改当前批次录入内容
    gotoBookfile(row) {
      localStorage.setItem("StrLotNumber", row.StrLotNumber);
      this.$router.push("/bookfile");
    },
    // 弹出根据批号添加图书对话框
    BookFormCreate(row) {
      this.formLRTSModel = {
        StrLibraryID: "",
        StrLibraryName: "",
      };
      this.isBarCodeAutoincrement = false
      this.formLRTSModel.IntReservationRoyalty = 1;
      this.formLRTSModel.IntMemoryCopyNum = 1;
      this.formLRTSModel.IntNumberCopies = 1;
      this.StrLotNumber = row.StrLotNumber;
      this.StrLotNumberID = row.StrID;
      this.formLRTSModel.StrLotNumber = this.StrLotNumber;
      this.formLRTSModel.StrLotNumberID = this.StrLotNumberID;
      this.dialogLRTSVisible = true;
      this.fileList = [];
      this.formCXTSModel.keywords = "";
      this.$nextTick(() => {
        this.$refs["tsDataForm"].clearValidate();
      });
      this.getLibraryList();
      //获取书架
      // this.LibraryChange(this.formLRTSModel.StrLibraryID)
    },
    //获取默认的图书馆
    DefaultLibrary() {
      console.log(this.swList[0].StrID+localStorage.getItem("defaultLibraryID"))
      if(localStorage.getItem("defaultLibraryID") === null||localStorage.getItem("defaultLibraryID")===""){
        this.formLRTSModel.StrLibraryID = this.swList[0].StrID
        this.formLRTSModel.StrLibraryName = this.swList[0].StrName
        console.log(this.swList[0].StrID)
      }else{
        this.formLRTSModel.StrLibraryID =
            localStorage.getItem("defaultLibraryID");
        this.formLRTSModel.StrLibraryName =
            localStorage.getItem("StrLibraryName");
      }
      //获取书架
      this.LibraryChange(this.formLRTSModel.StrLibraryID)
      this.getBookcode(this.formLRTSModel.StrLibraryID);
    },

    // 批号删除
    handleDelete(row) {
      this.$confirm(
          '确定要删除该批号?',
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            dangerouslyUseHTMLString: true,
            type: "warning",
          }
      )
          .then(() => {
            this.$axios({
              url: this.localpath + "lotnumbers/" + row.StrID,
              method: "delete",
              //发送格式为json
              headers: {
                "Content-Type": "application/json",
                dataType: "json",
                Authorization: localStorage.getItem("token"),
              },
            }).then((res) => {
              if (res.data.ok) {
                this.$notify({
                  message: "删除成功",
                  type: "success",
                  duration: 2000,
                });
                this.getLotNumberList();
              } else {
                this.$notify({
                  message: res.data.msg,
                  type: "error",
                  duration: 2000,
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    //设置图片URL
    toSetCover(coverUrl,ISBN){
      if (coverUrl !== "") {
        this.fileList=[]
        this.checkImage(coverUrl)
            .then((result) => {
              if (result) {
                // 图片路径可访问
                this.fileList.push({
                  url: coverUrl,
                });
              } else {
                // 图片路径无法访问，添加默认图片URL
                this.fileList.push({
                  url: require('../../assets/img/defaultBook.png'),
                });
              }
            })
            .catch(() => {
              // 图片路径检查出错，添加默认图片URL
              this.fileList.push({
                url: require('../../assets/img/defaultBook.png'),
              });
            });
      }else{
        const imgsrc = 'https://sanyue-oss-public.oss-cn-hangzhou.aliyuncs.com/image/bookcover/' + ISBN + '.jpg'
        this.checkImage(imgsrc)
            .then((result) => {
              if (result) {
                // 图片路径可访问
                this.fileList.push({
                  url: imgsrc,
                });
              } else {
                // 图片路径无法访问，添加默认图片URL
                this.fileList.push({
                  url: require('../../assets/img/defaultBook.png'),
                });
              }
            })
            .catch(() => {
              // 图片路径检查出错，添加默认图片URL
              this.fileList.push({
                url: require('../../assets/img/defaultBook.png'),
              });
            });
      }
    },
    // 查询修改
    BookUpdate(row) {
      this.fileList = [];
      this.$axios({
        url: this.localpath + "books/" + row.StrID,
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.formLRTSModel = res.data.msg;
          this.formLRTSModel.StrLotNumber = this.StrLotNumber;
          this.formLRTSModel.StrLotNumberID = this.StrLotNumberID;
          this.getBookcode(this.formLRTSModel.StrLibraryID)
          this.toSetCover(this.formLRTSModel.StrCoverURL,this.formLRTSModel.StrISBN)
        }
      });
      this.dialogCXJGVisible = false;
    },
    checkImage(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = function() {
          resolve(true); // 图片加载成功
        };
        img.onerror = function() {
          resolve(false); // 图片加载失败
        };
        img.src = url;
      });
    },
    // 查询
    BookSearch(val) {
      //处理非法字符 只提取数字
      this.formCXTSModel.keywords = this.formCXTSModel.keywords.replace(
          /[^0-9]/gi,
          ""
      );
      //联机查询
      if (val === 2) {
        if (this.formCXTSModel.keywords == "") {
          return this.$message.error(
              "请输入ISBN"
          );
        }
        if (this.formCXTSModel.keywords.length != 10 && this.formCXTSModel.keywords.length != 13) {
          return this.$message.error(
              "请输入正确的ISBN"
          );
        }
      }
      if (val === 1) {
        const param = {
          Search: {},
          OrderBy: "DTCreatedDate",
          intNotOn: "1",
          Sort: "-1",
          Page: this.cxjgQuery.Page,
          Perpage: this.cxjgQuery.Pager,
        };
        param.Search[this.formCXTSModel.keyName] = this.formCXTSModel.keywords;
        // console.log(param);
        this.$axios({
          url: this.localpath + "books/search",
          method: "post",
          data: JSON.stringify(param),
          //发送格式为json
          headers: {
            "Content-Type": "application/json",
            dataType: "json",
            Authorization: localStorage.getItem("token"),
          },
        }).then((res) => {
          if (res.data.ok) {
            this.cxjgLoading = false;
            this.cxjgList = res.data.msg;
            this.tsTotal = res.data.total;
          }
        });
        this.dialogCXJGVisible = true;
      } else if (val === 2) {
        this.isTSCXQuery = true;
        const param = {
          keyName: this.formCXTSModel.keyName,
          keywords: this.formCXTSModel.keywords,
        };
        this.$axios({
          url: this.localpath + "system/isbn/" + param.keywords,
          method: "get",
          //发送格式为json
          headers: {
            "Content-Type": "application/json",
            dataType: "json",
            Authorization: localStorage.getItem("token"),
          },
        }).then((res) => {
          if (res.data.ok) {
            this.formLRTSModel.StrRemark = res.data.msg.Annotations
            this.formLRTSModel.StrOtherResponsible = res.data.msg.OtherResponsible
            this.formLRTSModel.StrSectionName = res.data.msg.SectionName
            this.formLRTSModel.StrSectionNumber = res.data.msg.SectionNum
            this.formLRTSModel.StrSeriesTitle = res.data.msg.SeriesTitle
            this.formLRTSModel.StrPositiveTitle = res.data.msg.Title;
            this.formLRTSModel.StrLotNumber = this.StrLotNumber;
            this.formLRTSModel.StrLotNumberID = this.StrLotNumberID;
            this.formLRTSModel.StrSubtitle = res.data.msg.Subtitle;
            this.formLRTSModel.StrFirstResponsible = res.data.msg.Author;
            this.formLRTSModel.StrBinding = res.data.msg.Binding;
            this.formLRTSModel.StrNumberPages = res.data.msg.Page.toString();
            this.formLRTSModel.StrISBN = res.data.msg.ISBN;
            this.formLRTSModel.StrPublicationDate = res.data.msg.Pubdate;
            this.formLRTSModel.StrPublisherPlace = res.data.msg.Pubplace;
            this.formLRTSModel.StrPublisher = res.data.msg.Publisher;
            this.formLRTSModel.StrKeywords = res.data.msg.Keyword;
            this.formLRTSModel.StrTypeName = res.data.msg.Class;
            this.formLRTSModel.FloatPricePer = res.data.msg.Price;
            this.formLRTSModel.StrVersion = res.data.msg.Edition;
            this.formLRTSModel.StrSummary = res.data.msg.Summary;
            // if (this.formLRTSModel.StrCover === undefined) {
            //   this.formLRTSModel.StrCover =
            //       this.localpath + "covers/" + this.formCXTSModel.keywords;
            // }
            this.formLRTSModel.StrCoverURL = res.data.msg.Cover
            this.fileList=[]
            // this.fileList.push({url: this.formLRTSModel.StrCoverURL})
            this.toSetCover(this.formLRTSModel.StrCoverURL,this.formLRTSModel.StrISBN)
            this.isTSCXQuery = false;
            this.$refs.bookBarcode.focus()
          } else {
            this.$notify({
              message: res.data.msg,
              type: "error",
              duration: 2000,
            });
            this.isTSCXQuery = false;
          }
        }).catch(() => {
          this.isTSCXQuery = false;
          this.$message({
            type: "error",
            message: "服务异常,请检查ISBN",
          });
        });
      }
    },
    //添加图书
    BookCreate() {
      // if(this.checkSpecificKey(this.formLRTSModel.StrTypeName)){
      //   this.$message({
      //     message:'分类号不能包含.以外的其他符号,请检查分类号',
      //     type: 'error'
      //   })
      // }else{
      this.$refs["tsDataForm"].validate((valid) => {
        if (valid) {
          //判断一下条形码位数是否等于所需位数
          if (
              this.formLRTSModel.StrBookBarcode.length !==
              parseInt(this.IntBookBarcodeLength)
          ) {
            return this.$message.error(
                "条形码位数必须" + this.IntBookBarcodeLength + "位"
            );
          }
          //校验条形码
          var StrBookBarcodepattern = /^[A-Za-z0-9]+$/;
          if (
              StrBookBarcodepattern.test(this.formLRTSModel.StrBookBarcode) ==
              false
          ) {
            return this.$message({
              type: "error",
              message: "只能输入数字或字母",
            });
          }
          this.formLRTSModel.IntReservationRoyalty = parseInt(
              this.formLRTSModel.IntReservationRoyalty
          );
          this.formLRTSModel.IntMemoryCopyNum = parseInt(
              this.formLRTSModel.IntMemoryCopyNum
          );
          this.formLRTSModel.IntNumberCopies = parseInt(
              this.formLRTSModel.IntNumberCopies
          );
          this.formLRTSModel.FloatPricePer =
              this.formLRTSModel.FloatPricePer.toString();
          // 判断并获取书屋名称，其他地方只需要保证id准确即可
          for (var i = 0; i < this.swList.length; i++) {
            if (this.swList[i].StrID === this.formLRTSModel.StrLibraryID) {
              this.formLRTSModel.StrLibraryName = this.swList[i].StrName
            }
          }
          // 书架
          for (var j = 0; j < this.sjList.length; j++) {
            if (this.sjList[j].StrID === this.formLRTSModel.StrBookshelfID) {
              this.formLRTSModel.StrBookshelfName = this.sjList[j].StrName
            }
          }
          this.formLRTSModel.StrLotNumber = this.StrLotNumber;
          // console.log(this.formLRTSModel);
          this.$axios({
            url: this.localpath + "books",
            method: "put",
            data: JSON.stringify(this.formLRTSModel),
            //发送格式为json
            headers: {
              "Content-Type": "application/json",
              dataType: "json",
              Authorization: localStorage.getItem("token"),
            },
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$refs.checkContent.focus()
              localStorage.setItem("defaultLibraryID",this.formLRTSModel.StrLibraryID);
              localStorage.setItem("StrLibraryName",this.formLRTSModel.StrLibraryName);
              this.finallyBarCoder = this.addAndFormatString(this.formLRTSModel.StrBookBarcode,this.formLRTSModel.IntNumberCopies)
              // this.btnEmpty(this.StrLotNumber);
              this.btnEmpty();
              //实现条形码自增
              if(this.isBarCodeAutoincrement){
                this.formLRTSModel.StrBookBarcode = this.finallyBarCoder
              }
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        }
      });
    },
    //字符串转换为数字，然后和数字相加，相加完后，转换为字符串，并且前面补0变成原来的位数
    addAndFormatString(str, numberToAdd) {
      // 将字符串转换为数字
      const num = parseInt(str);
      // 进行相加操作
      const result = num + numberToAdd;
      // 将结果转换为字符串，并在前面补零
      const formattedResult = result.toString().padStart(str.length, '0');
      return formattedResult;
    },
    //报错
    BookRecorrect() {
      // 判断并获取书屋名称，其他地方只需要保证id准确即可
      for (var i = 0; i < this.swList.length; i++) {
        if (this.swList[i].StrID === this.formLRTSModel.StrLibraryID) {
          this.formLRTSModel.StrLibraryName = this.swList[i].StrName
        }
      }
      this.$axios({
        url: this.localpath + "system/reporterror",
        method: "post",
        data: JSON.stringify(this.formLRTSModel),
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 清空
    btnEmpty() {
      this.formLRTSModel = {
        StrLibraryID: "",
        StrLibraryName: "",
      };
      this.formLRTSModel.IntReservationRoyalty = 1;
      this.formLRTSModel.IntMemoryCopyNum = 1;
      this.formLRTSModel.IntNumberCopies = 1;
      // this.StrLotNumber = row.StrLotNumber;
      this.dialogLRTSVisible = true;
      (this.isTSCXQuery = false), (this.fileList = []);
      this.formCXTSModel.keywords = "";
      this.$nextTick(() => {
        this.$refs["tsDataForm"].clearValidate();
      });
      this.getLibraryList();
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage;
      this.getLotNumberList();
    },
    currPageChange(Page) {
      this.listQuery.Page = Page;
      this.getLotNumberList();
    },
    cxjgPageSizeChange(Perpage) {
      this.cxjgQuery.Perpage = Perpage;
      this.BookSearch(1);
    },
    cxjgCurrPageChange(Page) {
      this.cxjgQuery.Page = Page;
      this.BookSearch(1);
    },
  },
};
</script>
<style scoped>
.disabled /deep/ .el-upload--picture-card {
  display: none;
}

/* 图片上传 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.el-upload--text {
  width: 180px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

